.container {
    text-align: left;
    padding: 40px;
}

.subtitle {
    font-weight: 400;
}

.denseTable {
    max-width: 60%;
    min-width: 500px;
    margin-bottom: 50px;
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
}

.media {
    height: 140px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
    border: #b8b8b8 solid 2px;
    border-radius: 5px;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

.cardContentContainer {
    flex-direction: row;
    display: flex;
}

.orangeCard {
    border: 2px solid #fe9365;
    border-radius: 20px !important;
}

.greenCard {
    /* background: linear-gradient(to right,#0ac282,#0df3a3); */
    border: 2px solid #0ac282;
    border-radius: 20px !important;
}

.redCard {
    /* background: linear-gradient(to right,#fe5d70,#fe909d); */
    border: 2px solid #fe5d70;
    border-radius: 20px !important;
}

.orangeIcon {
    color: #fe9365;
    font-size: 65px !important;
    margin-top: 5px;
}

.greenIcon {
    color: #0ac282;
    font-size: 65px !important;
    margin-top: 5px;
}

.redIcon {
    color: #fe5d70;
    font-size: 65px !important;
    margin-top: 5px;
}

.whiteFont {
    color: #fff;
}

.greenFont {
    color: #0ac282;
    font-size: 50px !important;
    text-align: center;
}

.orangeFont {
    color: #fe9365;
    font-size: 50px !important;
    text-align: center;
}

.redFont {
    color: #fe5d70;
    font-size: 50px !important;
    text-align: center;
}

.mediumCardText {
    font-size: 16px!important;
    color: rgb(0, 0, 0);
}

.gradientButton {
    background: linear-gradient(to right, #c0bdbd, #eeecec);
    border: 0;
    box-shadow: 0 1px 5px 2px rgba(255, 105, 135, .3);
    color: #000 !important;
    text-transform: none !important;
    height: 52;
    padding: 3px 30px;
    width: 150px;
    font-size: 14px !important;
}

.tableCellClickable {
    cursor: pointer;
    color: #2892ca;
}