.denseTable {
    margin-bottom: 20px;    
  }
  
  .tableHeader {
    color: #152C7D !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    width: fit-content;
    word-wrap: break-word
  }
  
  .tableCell {
    width: fit-content;
    word-wrap: break-word;
    word-break: break-word;
    min-width: 100px;
  }
  
  .tableHeader110 {
    color: #152C7D !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 110px;
    max-width: 110px;
    word-wrap: break-word
  }
  
  .tableCell110 {
    min-width: 110px;
    max-width: 110px;
    word-wrap: break-word
  }
  
  .tableHeader250 {
    color: #152C7D !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 250px;
    max-width: 250px;
    word-wrap: break-word
  }
  
  .tableCell250 {
    min-width: 250px;
    max-width: 250px;
    word-wrap: break-word
  }